import axios, { AxiosError, AxiosResponse } from 'axios';

import { useMutation } from '@tanstack/react-query';

export type IImageUploadRequest = {
  file: FormData;
};

export function useImageUpload() {
  return useMutation<AxiosResponse<string>, AxiosError, IImageUploadRequest>({
    mutationFn: (payload) =>
      axios.post('/image-service/s3/', payload.file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
  });
}
